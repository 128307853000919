const title = "tayse-logo";

export default function WMSImageLogo({ className, dark = false }) {
  return (
    <img
      src={dark ? "tayse-dark.png" : "tayse-light.png"}
      alt={title}
      className={className}
    />
  );
}
